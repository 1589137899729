import Disqus from "disqus-react";
import Markdown from 'markdown-to-jsx';
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";

function BlogDetails(props) {
  const [content, setContent] = useState("");
  const blogId = props.match.params.id;
  const blogFile = props.match.params.title;
  const fileName = `${blogFile}.md`;

  useEffect(() => {
    import(`../blog/${fileName}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setContent(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
  });

  const disqusShortname = "chester-react"; //found in your Disqus.com dashboard
  const disqusConfig = {
    url: "https://tf-react-chester.now.sh/", //Homepage link of this site.
    identifier: blogId,
    title: blogFile,
  };

  return (
    <Layout>
      <Helmet>
        <title>Blog Details - Ali Murtaza - UI & Graphic Designer | Video Editor</title>
        <meta
          name="description"
          content="Ali Murtaza - UI & Graphic Designer | Video Editor Blog Details Page"
        />
      </Helmet>
      <div className="mi-blog-details mi-section mi-padding-top mi-padding-bottom">
        <div className="container">
            <Markdown>
                {content}
            </Markdown>
          <div className="mi-blog-details-comments mt-30">
            <Disqus.DiscussionEmbed
              shortname={disqusShortname}
              config={disqusConfig}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default BlogDetails;
