import Mock from "../mock";

const database = {
  information: {
    name: 'Ali Murtaza',
    aboutContent: "Seeking a challenging opportunity in a dynamic environment where innovation, education and sense of ownership are valued and encouraged. Living the dreams is a kind of person I am. I go for a career which could inspire me, ultimately benefiting Both myself & the organization.",
    age: 27,
    phone: '',
    nationality: 'Pakistani',
    language: 'English, Urdu',
    email: 'alimurtaza877@gmail.com',
    address: '654 block A Eden Lane Villas 2, Lahore, Pakistan',
    Job: 'Available',
    socialLinks: {
      facebook: 'https://www.facebook.com/ali.happii/',
      twitter: '',
      pinterest: '',
      behance: 'https://www.behance.net/alimurtaza2',
      linkedin: 'https://www.linkedin.com/in/ali-murtaza-508b7a139/',
      dribbble: '',
      github: ''
    },
    brandImage: '/images/brand-image.png',
    aboutImage: '/images/about-image.jpg',
    aboutImageLg: '/images/about-image-lg.jpg',
    cvfile: '/files/AliResume.pdf'
  },
  services: [
    {
      title: "UI Designer",
      icon: 'brush-alt',
      details: "User Interface Designer — designs all the screens through which a user will move while using a website, creating the visual design elements"
    },
    {
      title: "Graphic Designer",
      icon: 'code',
      details: "create visual concepts, using computer Adobe suite or by hand, to communicate ideas that inspire, inform, and captivate consumers. I develop the overall layout and production design for advertisements, social media posts, banners for website, carousels adds, brochures, magazines, reports etc.."
    },
    {
      title: "Video Editing",
      icon: 'mobile',
      details: "I manage material such as camera footage, dialogue, sound effects, graphics and special effects to produce a final film or video product. "
    },
    {
      title: "Digital Media Marketing",
      icon: 'mobile',
      details: "digital channels to reach customers, build brand awareness, and promote products and services."
    },
    {
      title: "Email Marketing - ZOHO Campaign/CRM, Active Campaign",
      icon: 'mobile',
      details: "aware of new products, discounts, and other services."
    },





  ],
  reviews: [
    {
      id: 1,
      content: "Our company has hired Ali for multiple work tasks includes (Social Media Markting, Graphics, Website handling, Video editing & Animations), and each time his quality of work is amazing and he gets the job done quickly. ",
      author: {
        name: 'Zarmeen Mahmood',
        designation: 'Marketing Manager, BlueEast | Orient Group of Company'
      }
    },
    {
      id: 2,
      content: "The reason why I hired Ali is because he understands and delivers what I need from a few words. He has an excellent attention to detail, He is hardworking and very talented Video Editor & Graphic Designer who delivers breath-taking designs.",
      author: {
        name: 'Ahmed Afridi',
        designation: 'Creative Director, University of South Asia | Din Media Group'
      }
    },

  ],
  skills: [
    {
      title: "Adobe XD",
      value: 85
    },
    {
      title: "Adobe Photoshop",
      value: 80
    },
    {
      title: "Adobe Illustrator",
      value: 70
    },
    {
      title: "Adobe Premiere Pro",
      value: 85
    },
    {
      title: "Zoho Campaign",
      value: 80
    },
    {
      title: "Zoho CRM",
      value: 65
    },
    {
      title: "MS Office",
      value: 80
    },
    {
      title: "Active Campaign",
      value: 85
    }
  ],
  portfolios: [

    {
      id: 1,
      title: "UI Designs for BlueJay Health",
      subtitle: "Website UI Designs.",
      imageUrl: "/images/portfolio-image-4.jpg",
      largeImageUrl: [
        "/images/portfolio-image-4-lg.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760137/website%20/portfolio-image-4-lg2_yi3jaw.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760050/website%20/06_gl4iqn.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760050/website%20/04_e7nvkn.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760049/website%20/07_o8gqv2.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760049/website%20/07_o8gqv2.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760049/website%20/02_m5r5jr.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760046/website%20/05_t4ljo5.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639760051/website%20/03_hax4ub.png"
      ],
      url: 'https://www.bluejayhealth.com/'
    },
    {
      id: 2,
      title: "UI Designs for BlueJay Restorehealth",
      subtitle: "Website UI Designs.",
      imageUrl: "/images/portfolio-image-2.jpg",
      largeImageUrl: [
        "/images/portfolio-image-3-lg3.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639761310/website%20/screencapture-bluejayrestorehealth-2021-12-17-22_07_32_ncecah.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639761309/website%20/screencapture-bluejayrestorehealth-Organizations-2021-12-17-22_08_40_wlgcc3.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639761305/website%20/screencapture-bluejayrestorehealth-Individuals-2021-12-17-22_08_21_suijrm.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639761304/website%20/screencapture-bluejayrestorehealth-blogs-2021-12-17-22_08_55_uyyyj9.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639761302/website%20/screencapture-bluejayrestorehealth-contactUs-2021-12-17-22_09_07_wmeog7.png"
      ],
      url: 'https://www.bluejayrestorehealth.com/'
    },
    {
      id: 3,
      title: "BlueJay Mobile Health",
      subtitle: "Social Posts | Newsletters | Flyers",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639917953/website%20/product11_33_e0km59.jpg",
      largeImageUrl: [
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639917964/website%20/product11_32_okei7e.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639917963/website%20/product11_30_hyjjat.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639917987/website%20/product11_31_fq1aoj.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639918947/website%20/product11_34_xqtra8.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920021/website%20/product11_35_owdzdm.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920014/website%20/product11_36_ostrdg.jpg"
      ],
      url: ''
    },
    {
      id: 4,
      title: "Product Design",
      subtitle: "UI Design for Products",
      imageUrl: "/images/product11.jpg",
      largeImageUrl: [
        "/images/product11.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639833563/website%20/screencapture-reason-pk-collections-led-tv-top-products-striker-39-smart-led-tv-2021-12-18-16_37_40_krsdj2.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639819208/website%20/product0_kolozq.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639819201/website%20/product3_pzuay3.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639819150/website%20/product2_m31fv1.png",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639819111/website%20/product1_mo01yq.png"
      ],
      url: 'https://www.behance.net/gallery/133446507/Product-Designs'
    },
    {
      id: 5,
      title: "Orient Group of Companies",
      subtitle: "Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639916433/website%20/product11_24_xddopv.jpg",
      largeImageUrl: [
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639916444/website%20/product11_26_i2y7uj.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639916447/website%20/product11_25_jdexm5.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639916448/website%20/product11_27_hluinn.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639916454/website%20/product11_28_whoq65.jpg"
      ],
      url: ''
    },
    {
      id: 5,
      title: "BYOU Fashion Brand",
      subtitle: "Logo | Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920802/website%20/product11_40_zcfh05.jpg",
      largeImageUrl: [
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920808/website%20/product11_39_id2lcl.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920815/website%20/product11_37_mnvm8c.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639920819/website%20/product11_38_hgamjh.jpg"
      ],
      url: ''
    },

    {
      id: 5,
      title: "Shahsawar Suiting Brand",
      subtitle: "Logo | Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834555/website%20/product11_1_njrkqi.jpg",
      largeImageUrl: [
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834555/website%20/product11_1_njrkqi.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834577/website%20/product11_3_xw6alx.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834564/website%20/product11_2_oei2tg.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834570/website%20/product11_4_xj6s2a.jpg",
        "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639834566/website%20/product11_5_xvuw0h.jpg"
      ],
      url: ''
    },


    {
      id: 10,
      title: "Charactor Design",
      subtitle: "Designs",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639857287/website%20/product11_17_dsph2x.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639857244/website%20/e8051683349901.5d397b0089f6d_mlw6cz.gif", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639857242/website%20/0c0e1483349901.5d397776b96a6_fb9sww.jpg"],
      url: ''
    },
    {
      id: 11,
      title: "Charactor Design",
      subtitle: "Designs",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639858690/website%20/product11_18_mp1koe.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639858696/website%20/1c35dd83499119.5d3ec523581d5_xfctwl.gif", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639858695/website%20/80f78e83606595.5d419bbb6ad4e_id1kma.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639858692/website%20/028b1b83499119.5d3ec5235870f_bpey40.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639858683/website%20/d1cd4583398853.5d3ae0a5246c3_pfetvi.jpg"],
      url: ''
    },
    {
      id: 8,
      title: "TEEMOJI",
      subtitle: "Logo | Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836027/website%20/product11_14_ojnqss.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836027/website%20/product11_14_ojnqss.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836032/website%20/c475f383168539.5d3470b998390_k2at6i.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836030/website%20/a91dd383168539.5d3470b99789f_tlutex.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836030/website%20/abeaee83168539.5d3470b998597_fgc7s2.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836032/website%20/416fad83168539.5d3470b9991a2_esov6q.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836037/website%20/deb8a483168539.5d3470b997d1c_xf219q.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836049/website%20/51338f83168539.5d3470b998c71_jarzrm.png"],
      url: ''
    },
    {
      id: 7,
      title: "Pakistan Hockey Super League (PHSL Promo)",
      subtitle: "Video editor | Assistant Director",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639859790/website%20/product11_20_gfsgxv.jpg",
      largeImageUrl: ["https://www.youtube.com/watch?v=sVYdoH-uZuI"],
      url: ''
    },
    {
      id: 9,
      title: "Tee Shirt Designs",
      subtitle: "Design for T.Shirts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836511/website%20/product11_15_nl6yot.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836511/website%20/product11_15_nl6yot.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836402/website%20/1f2c4c85598529.5d80d9ae5eb95_q75urr.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836402/website%20/7ea76385598529.5d80d9ae5f10e_bpjxp4.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836395/website%20/a50dd285598529.5d80d9ae5be11_a5tun6.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836395/website%20/b4d9f085598529.5d80d9ae611ed_dir65f.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836394/website%20/a1570985598529.5d80d9ae606f3_jmlmmx.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836393/website%20/a3af0885598529.5d80d9ae60140_buupik.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836391/website%20/9471c385598529.5d80d9ae60c52_ty16eo.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836391/website%20/a9a33885598529.5d80d9ae5df76_xbtvde.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836388/website%20/8cd24b85598529.5d80d9ae5f68c_duczey.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836388/website%20/52da0985598529.5d80d9ae5e4f1_abb8jv.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836385/website%20/0d28e285598529.5d80d9ae5fbe3_j4hc05.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639836382/website%20/cf684185598529.5d80d9ae5c385_kmvcta.jpg"],
      url: ''
    },
    {
      id: 14,
      title: "Bano Herbal",
      subtitle: "Logo | Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835645/website%20/product11_7_fp5c3a.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835645/website%20/product11_7_fp5c3a.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835677/website%20/product11_13_vc2vr5.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835672/website%20/product11_9_g9xzwk.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835669/website%20/product11_11_vxytmo.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835676/website%20/product11_8_knta8i.jpg"],
      url: ''
    },

    {
      id: 12,
      title: "KUNWARI Short Film/Drama",
      subtitle: "Producer | Video editor | Assistant Director",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639860234/website%20/product11_21_dxurvl.jpg",
      largeImageUrl: ["https://www.youtube.com/watch?v=pjtzjmMkeYc&t=258s"],
      url: ''
    },
    {
      id: 15,
      title: "Wazir Khan Mosque",
      subtitle: "Video editor | Assistant Director",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639860359/website%20/product11_22_tq9kii.jpg",
      largeImageUrl: ["https://www.youtube.com/watch?v=yO-arRGZRUg&t=32s"],
      url: ''
    },
    {
      id: 16,
      title: "University Of South Asia ! Guests Reviews Thesis Project",
      subtitle: "Producer | Video editor | Assistant Director",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639860561/website%20/product11_23_nofnr0.jpg",
      largeImageUrl: ["https://www.youtube.com/watch?v=doO4uKoTcLE"],
      url: ''
    },
    {
      id: 6,
      title: "ClickMall",
      subtitle: "Logo | Banners | Social Posts",
      imageUrl: "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835159/website%20/product11_6_orqblv.jpg",
      largeImageUrl: ["https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835158/website%20/9a099183167721.5d346a446f775_o7w0gx.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835156/website%20/55f44a83167721.5d346a446f105_rgsgad.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835162/website%20/1a431083167721.5d346a446edd5_wurgxv.jpg", "https://res.cloudinary.com/alimurtaza-co/image/upload/q_auto:low/v1639835164/website%20/3a436683167721.5d346a446e741_zv64xb.jpg"],
      url: ''
    },

  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "06/2020 - Present",
        position: "Digital Marketing Executive | Graphic Designer | Video Editor | UI Designer",
        company: "BlueJay Mobile Health",
        details: "In BlueJay Mobile Health, I have to design all the campaigns which include (digital posts, Newsletters, Posters, Banners, Ads, etc), I have to edit the product videos (rough cut to final cut), I have to manage all digital channels, also send the newsletter through Zoho Campaign, send the surveys through a different platform. I also manage the digital marketing side, I have to design the UI for the application if needed as well as the website UI. "
      },
      {
        id: 2,
        year: "02/2019 - 06/2020",
        position: "Graphic Designer",
        company: "BLUEEAST (PVT) LTD/Orient Group of Company",
        details: "Design or create graphics to meet specific commercial or promotional needs, such as banners, posts , displays, or logos. May use a variety of mediums to achieve artistic or decorative effects."
      },
      {
        id: 3,
        year: "09/2018 - 01/2019",
        position: "Assistant Director Creative Dept.",
        company: "University of South Asia",
        details: "Utilize my expertise in film making by editing as well as I must take responsibility for a number of important practicalities including breaking down the script, leading recipes and production meetings and working with the director and key personnel to determine the shooting schedule and how long each scene will take to film."
      },
      {
        id: 4,
        year: "09/2018 - 02/2019",
        position: "Creative Dept Video Editor/Graphic Designer Internship",
        company: "Royal Media Network (PVT.)LTD & Channel(City 42)",
        details: ""
      }
    ],
    educationExperience: [
      {
        id: 1,
        year: "02/2014 - 02/2018",
        graduation: "Bachelors in Computer Science (BSCS)",
        university: "COMSATS University, Lahore",
        details: ""
      },
      {
        id: 2,
        year: "09/2018 - 12/2018,",
        graduation: "(Adobe Photoshop/Illustration/Premiere)",
        university: "University of South Asia",
        details: ""
      },

    ]
  },
  blogs: [
    //   {
    //     id: 1,
    //     title: 'Markdown & Html supported blog.',
    //     featuredImage: '/images/blog-image-1.jpg',
    //     filesource: '../../blog/markdown-html-supported-blog.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 2,
    //     title: 'Installing NodeJS on your device.',
    //     featuredImage: '/images/blog-image-2.jpg',
    //     filesource: '../../blog/installing-nodejs-on-your-device.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 3,
    //     title: 'UI/UX design starter with Adobe XD.',
    //     featuredImage: '/images/blog-image-3.jpg',
    //     filesource: '../../blog/uiux-design-starter-with-adobe-xd.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 4,
    //     title: 'Boost your post for increasing sales.',
    //     featuredImage: '/images/blog-image-4.jpg',
    //     filesource: '../../blog/boost-your-post-for-increasing-sales.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 5,
    //     title: 'Difference between GatsbyJS & NextJS.',
    //     featuredImage: '/images/blog-image-5.jpg',
    //     filesource: '../../blog/difference-between-gatsbyjs-and-nextjs.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 6,
    //     title: 'How to choose javascript framework for project.',
    //     featuredImage: '/images/blog-image-6.jpg',
    //     filesource: '../../blog/how-to-choose-javascript-framework-for-project.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 7,
    //     title: 'Web automation with python language.',
    //     featuredImage: '/images/blog-image-7.jpg',
    //     filesource: '../../blog/web-automation-with-python-language.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 8,
    //     title: 'Time to use latest technology for creating a website.',
    //     featuredImage: '/images/blog-image-8.jpg',
    //     filesource: '../../blog/time-to-use-latest-technology-for-creating-a-website.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 9,
    //     title: 'Think out of the box.',
    //     featuredImage: '/images/blog-image-9.jpg',
    //     filesource: '../../blog/think-out-of-the-box.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 10,
    //     title: 'Trending designs in 2020.',
    //     featuredImage: '/images/blog-image-1.jpg',
    //     filesource: '../../blog/trending-designs-in-2020.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 11,
    //     title: 'How to get 10k instagram followers?',
    //     featuredImage: '/images/blog-image-2.jpg',
    //     filesource: '../../blog/how-to-get-10k-instagram-followers.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 12,
    //     title: 'What NodeJS can do?',
    //     featuredImage: '/images/blog-image-3.jpg',
    //     filesource: '../../blog/what-nodejs-can-do.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 13,
    //     title: 'Futures of javascript.',
    //     featuredImage: '/images/blog-image-4.jpg',
    //     filesource: '../../blog/future-of-javascript.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 14,
    //     title: 'Popular javascript library in 2020.',
    //     featuredImage: '/images/blog-image-5.jpg',
    //     filesource: '../../blog/popular-javascript-library-in-2020.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   },
    //   {
    //     id: 15,
    //     title: 'Promrammers must read books.',
    //     featuredImage: '/images/blog-image-6.jpg',
    //     filesource: '../../blog/programmers-must-read-books.md',
    //     createDay: "20",
    //     createMonth: 'February',
    //     createYear: "2020"
    //   }
  ],
  contactInfo: {
    phoneNumbers: ['+92-3146-525353', '+92-3331-462516'],
    emailAddress: ['alimurtaza877@gmail.com'],
    address: "654 block A Eden Lane Villas 2, Lahore, Pakistan"
  }
}


Mock.onGet("/api/information").reply(config => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply(config => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply(config => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply(config => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply(config => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply(config => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply(config => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply(config => {
  const response = database.contactInfo;
  return [200, response];
});